import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";


// import required modules
import { EffectCards } from "swiper";
import Slid from "./Slid";

export default function Slider2() {
    return (
        <div className="slider2-container">
            <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards]}
                className="mySwiper"
            >
                <SwiperSlide><Slid
                    detail="Masoud is one of the best freelancers I have worked with on Upwork. 
                He actually completed 90% of the job before we even created a contract, 
                and he worked several late nights to help me get a project back on track.
                 This gives you a very good idea of his work ethic. I would not hesitate 
                 to recommend him and I can't wait to work with him again in the future. Thanks Masoud!"
                    name="Dany Sharmez"
                    title="Create store website with react"
                /></SwiperSlide>
                <SwiperSlide><Slid
                    detail="Exceptional - one of the best developers
                Would recommend to everyone and look forward to working with Masoud again.
                A genuine pleasure. Delivered EXCELLENT results."
                    name="Adam Lovrens"
                    title="React Frontend Developer"
                /></SwiperSlide>
                <SwiperSlide><Slid
                    detail="Fantastic frontend developer. Very easy to work with &
                 performs all tasks requested on time & completed well."
                    name="Vahed Somar"
                    title="For Occasional Work"
                /></SwiperSlide>
                <SwiperSlide><Slid
                    detail="I was very pleased to work with Masoud on Olek5 project.
                 He build very good WordPress landing page. The work went well,
                 and he was available for questions and clarifications as needed.
                  I look forward to working on further projects with him."
                    name="Radvin Borf"
                    title="Create a landing page"
                /></SwiperSlide>
            </Swiper>
        </div>
    );
}
