import React from 'react'

export default function SectionHeader({title , subTitle}) {
  return (
    <div className='container px-4 mx-auto pt-4 pb-3 md:pt-8 md:pb-6'>
        <p className='color-gold1'>{subTitle}</p>
        <h2 className='text-xl md:text-4xl'>{title}</h2>
    </div>
  )
}
