import "./App.css";
import { useLocation, useRoutes } from "react-router-dom";
import routs from "./routs";
import { useEffect } from "react";
import { ThemeContextProvider } from "./Contexts/ThemeContext";

function App() {
  const router = useRoutes(routs);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [pathname]);

  return (
    <>
      <ThemeContextProvider>{router}</ThemeContextProvider>
    </>
  );
}

export default App;
