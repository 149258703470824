import React from "react";
import ChatButton from "../Button/ChatButton";

export default function About() {
  return (
    <div className=" container lg:p-10 py-8 bg-3">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2">
          <img src="./img/ab.png" className="img-100 p-5" alt="bahamin" />
        </div>
        <div className="w-full md:w-1/2 p-5">
          <p className="color-gold1 mb-6">ABOUT ME</p>
          <h2 className="text-2xl mb-6">Why hire me for your next project?</h2>
          <p className="mb-5">
            To leverage my experience with WordPress and web development as a
            Front End Developer, while continually expanding my knowledge and
            collaborating with a team to tackle new challenges.
            <br />
            <br />
            Specializes in Front-End Development
            <br />
            Frontend development is one of my main expertise. I have a
            experience developing: - Websites - Single Page applications -
            Converting Adobe XD designs - Converting Figma designs - Coding
            Themes and Templates
            <br />
            I always target modern, clean code and prioritize performance and
            scalability
            <br />
            <br />
            Preferred Technologies:
            <br />
            - ReactJS
            <br />
            - Typescript
            <br />
            - Tailwind
            <br />
          </p>
          <ChatButton title="LEARN MORE" />
        </div>
      </div>
    </div>
  );
}
