import { useState } from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Prices from '../../Components/Prices/Prices'
import SocialMedias from '../../Components/SocialMedias/SocialMedias'
import "./Price.css"
import Background from '../../Components/Animations/Background'

export default function Price() {

    const [tab, setTab] = useState("1")

    return (
        <>
            <Background />
            <div className=' relative  overflow-x-hidden'>
                <div className='price-container'>
                    <Header />
                    <div className='price-div'>
                        <div className='container px-4 mx-auto'>
                            <h1 className='text-2xl lg:text-4xl pt-32 -mt-24 mb-4'>Simple pricing that scales with your business</h1>
                            <p className='mb-3'>Whether you’re an business in growth mode or a game studio</p>
                            <p className='lg:w-3/4'>Please note that these prices are just estimates and can vary depending on the
                                complexity of the project and specific client requirements. It's always best
                                to contact us directly for a personalized quote based on your needs.
                            </p>
                        </div>
                    </div>
                </div>
                <Prices />
                <div className='container px-4 mx-auto'>

                    <h3 className='text-xl lg:text-2xl'>Get Professional Website Design & Development</h3>
                    <p className='md:w-3/4 mb-8'>level up your online presence with Bahamin' powerful and affordable web development services.</p>
                    <h3 className='text-xl lg:text-2xl'>Build Your Digital Presence Right with Bahamin.</h3>
                    <p className='md:w-3/4 mb-10'>Bahamin offers agile and efficient web development with cutting-edge technology that empowers businesses to reach their fullest potential. With our simple payment plans and modern web design, Bahamin enables you to create a stunning website in no time.</p>

                    <div className='flex flex-wrap md:flex-nowrap gap-1 md:gap-20  bg-3 rounded-xl p-8 h-screen md:h-fit'>
                        <div className='w-full md:w-1/5 h-1/2 md:h-fit'>
                            <p className={`${tab === "1" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-t  border-indigo-100 border-solid`}
                                onClick={() => setTab("1")}
                            >Advanced Technology</p>
                            <p className={`${tab === "2" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                                onClick={() => setTab("2")}
                            >Exceptional Quality</p>
                            <p className={`${tab === "3" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                                onClick={() => setTab("3")}
                            >Affordable Pricing</p>
                            <p className={`${tab === "4" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                                onClick={() => setTab("4")}
                            >Professional Design</p>
                            <p className={`${tab === "5" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                                onClick={() => setTab("5")}
                            >Responsive Design</p>

                        </div>
                        <div className='w-full md:w-4/5 relative h-1/2 md:h-fit'>
                            <div className={`${tab === "1" ? " opacity-100 top-0" : "opacity-0 -top-10"} absolute transition-all duration-700`}>
                                <p className='text-xl lg:text-2xl mb-5'>Advanced Technology</p>
                                <p className='lg:w-3/4'>Leverage the latest web technologies, such as HTML, CSS, JavaScript , and React to create powerful and responsive websites.</p>
                            </div>
                            <div className={`${tab === "2" ? " opacity-100 top-0" : "opacity-0 -top-10"} absolute transition-all duration-700`}>
                                <p className='text-xl lg:text-2xl mb-5'>Exceptional Quality</p>
                                <p className='lg:w-3/4'>Receive exceptional quality from our experienced professionals with our process-driven approach to website design and development.</p>
                            </div>
                            <div className={`${tab === "3" ? " opacity-100 top-0" : "opacity-0 -top-10"} absolute transition-all duration-700`}>
                                <p className='text-xl lg:text-2xl mb-5'>Affordable Pricing</p>
                                <p className='lg:w-3/4'>Our straightforward and competitive pricing makes it possible for businesses of all sizes to access quality web design and development services.</p>
                            </div>
                            <div className={`${tab === "4" ? " opacity-100 top-0" : "opacity-0 -top-10"} absolute transition-all duration-700`}>
                                <p className='text-xl lg:text-2xl mb-5'>Professional Design</p>
                                <p className='lg:w-3/4'>We work with a team of handpicked, experienced professionals to deliver creative and intuitive designs that align with your brand.</p>
                            </div>
                            <div className={`${tab === "5" ? " opacity-100 top-0" : "opacity-0 -top-10"} absolute transition-all duration-700`}>
                                <p className='text-xl lg:text-2xl mb-5'>Responsive Design</p>
                                <p className='lg:w-3/4'>Create a website that looks great across all devices, from desktop to mobile, with full access to the latest development trends.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <SocialMedias />
                <Footer />
            </div>
        </>
    )
}
