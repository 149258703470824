import React, { useLayoutEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/all";
import "./Animations.css";
import gsap from "gsap";

function AnimationDiv({ children, position, className }) {
  const divElement = useRef(null);

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }
    const funAnim = gsap.fromTo(
      divElement.current,
      {
        translateX: 1300,
      },
      {
        translateX: 1300,
        onComplete: animation,
        ease: "linear",
      }
    );
    return () => {
      funAnim.kill();
    };
  }, []);
  function animation() {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: divElement.current,
        start: "top bottom",
        end: "bottom bottom",
        // scrub: true,
      },
    });
    tl.to(divElement.current, {
      translateX: 0,
      duration: 2,
    });
  }
  return (
    <div
      data-scroll-section
      ref={divElement}
      className={` translate-x-[1300px] `}
    >
      <div data-scroll>{children}</div>
    </div>
  );
}

export default AnimationDiv;
