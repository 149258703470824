import React, { useState } from 'react'
import About from '../About/About'
import Awards from '../Awards/Awards'
import Exhibition from '../Exhibition/Exhibition'
import Projects from '../Projects/Projects'
import Service from '../Service/Service'
import Skills from '../Skills/Skills'
import "./MainInfos.css"

export default function MainInfos() {

    const [show, setShow] = useState("PROJECTS")

    return (
        <div className='my-8 '>
            <div className='container mx-auto py-8'>
                <div className='grid grid-cols-2 sm:grid-cols-4 text-center bg-4 border-t'>
                    <div onClick={() => setShow("PROJECTS")}
                        className={` py-5 cursor-pointer text-xs md:text-md infos-hover border-r border-l ${show === "PROJECTS" ? " infos-active" : ""}`}>PROJECTS</div>
                    <div onClick={() => setShow("ABOUT")}
                        className={` py-5 cursor-pointer text-xs md:text-md infos-hover border-r ${show === "ABOUT" ? " infos-active" : ""}`}>ABOUT</div>
                    <div onClick={() => setShow("SKILLS")}
                        className={` py-5 cursor-pointer text-xs md:text-md infos-hover border-r border-l ${show === "SKILLS" ? " infos-active" : ""}`}>SKILLS</div>
                    <div onClick={() => setShow("SERVICE")}
                        className={` py-5 cursor-pointer text-xs md:text-md infos-hover border-r ${show === "SERVICE" ? " infos-active" : ""}`}>SERVICE</div>
                    {/* <div onClick={() => setShow("AWARD")}
                        className={`md:w-1/4 w-1/2 py-5 cursor-pointer text-xs md:text-md infos-hover border-r ${show === "AWARD" ? " infos-active" : ""}`}>AWARD</div>
                    <div onClick={() => setShow("EXIBITION")}
                        className={`md:w-1/4 w-1/2 py-5 cursor-pointer text-xs md:text-md infos-hover ${show === "EXIBITION" ? " infos-active" : ""}`}>EXIBITION</div> */}
                </div>
                {
                    show === "PROJECTS" ? (<Projects />)
                        : show === "ABOUT" ? (<About />)
                            : show === "SKILLS" ? (<Skills />)
                                : show === "SERVICE" ? (<Service />)
                                    : show === "AWARD" ? (<Awards />)
                                        : (<Exhibition />)
                }

            </div>
        </div>
    )
}
