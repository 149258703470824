import { articles } from "../../Components/BlogComponents/Data/Data";
import NormalCard from "../../Components/BlogComponents/Cards/NormalCard";
import MainCard from "../../Components/BlogComponents/Cards/MainCard";
import Subscribe from "../../Components/BlogComponents/Subscribe/Subscribe";
import Header from "../../Components/BlogComponents/Header/Header";
import SliderBlog1 from "../../Components/BlogComponents/Sliders/SliderBlog1";
import ScrollTopButton from "../../Components/BlogComponents/ScrollTopButton/ScrollTopButton";
import Footer from "../../Components/BlogComponents/Footer/Footer";
import SocialMedias from "../../Components/BlogComponents/SocialMedias/SocialMedias";

export default function Index() {
  return (
    <div className="bg-white px-4  overflow-x-hidden">
      <Header />
      <div className="container px-4 md:px-0 max-w-6xl mx-auto ">
        <div className="mx-0 sm:mx-6">
          <h2 className="w-full text-center text-gray-600 text-md md:text-6xl pt-6 px-6 font-mono mb-5">
            BAHAMIN BLOG
          </h2>
          <p className="text-center text-slate-400 font-Montserrat text-xs mb-10 tracking-widest">
            A place to write and study
          </p>

          <div className=" w-full text-xl md:text-2xl text-gray-800 leading-normal rounded-t">
            <div className="flex flex-wrap justify-between pt-12 -mx-6">
              {[...articles].reverse().map((article, index) => {
                if (index > 11) return;
                if (index === 0)
                  return <MainCard key={article.id} {...article} />;
                if (index < 4)
                  return (
                    <NormalCard
                      widthclassName={"w-1/3"}
                      heightclassName="h-64"
                      key={article.id}
                      {...article}
                    />
                  );
                if (index < 6 && index > 3)
                  return (
                    <NormalCard
                      widthclassName={"w-1/3"}
                      heightclassName="h-full"
                      key={article.id}
                      {...article}
                    />
                  );
                if (index === 6)
                  return (
                    <NormalCard
                      widthclassName={"w-2/3"}
                      heightclassName="h-full"
                      key={article.id}
                      {...article}
                    />
                  );
                if (index === 7)
                  return (
                    <NormalCard
                      widthclassName={"w-1/3"}
                      heightclassName="h-64"
                      key={article.id}
                      {...article}
                    />
                  );
                if (index > 7)
                  return (
                    <NormalCard
                      widthclassName={"w-1/3"}
                      heightclassName="h-64"
                      key={article.id}
                      {...article}
                    />
                  );
                if (index === 11)
                  return (
                    <NormalCard
                      widthclassName={"w-full"}
                      heightclassName="h-64"
                      key={article.id}
                      {...article}
                    />
                  );
              })}
            </div>
          </div>

          <Subscribe />

          {/* <Author /> */}
        </div>

        <div>
          <h3 className="text-2xl my-4 text-slate-700">
            Articles you might like
          </h3>
          <div className="p-5 my-8 flex">
            <div className="flex justify-center items-center text-center p-1">
              <div>
                <p className=" font-Epic-Ride text-2xl">Instagram</p>
                <p className=" tracking-widest">BEST STORIS THIS WEEK</p>
              </div>
            </div>
            <SliderBlog1 />
          </div>
        </div>
        <div className="flex">
          <div className="w-full md:w-2/3 p-3 md:p-5">
            <h2 className="w-full text-center text-gray-600 text-md md:text-4xl pt-6 mb-4 px-6 font-Epic-Ride">
              Brazil
            </h2>
            <p className="text-center text-slate-800 font-Montserrat text-xl mb-4 tracking-widest">
              SAN FRANCISCO VACATIONS 2018
            </p>
            <p className="text-center text-slate-400 font-Montserrat text-xs mb-10 tracking-widest">
              TRAVELLIFE
            </p>
            <img
              src="https://source.unsplash.com/collection/3657445/800x600"
              width={800}
              className="mb-4"
              alt="bahamin"
            />
            <p className="mb-6">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum rem vero officiis in autem labore! Ipsa vitae optio
              dolores officiis ut cumque odio voluptatem earum! Consequuntur
              dolorum veritatis quaerat repudiandae! Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Voluptatum rem vero officiis in
              autem labore! Ipsa vitae optio dolores officiis ut cumque odio
              voluptatem earum! Consequuntur dolorum veritatis quaerat
              repudiandae! Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Voluptatum rem vero officiis in autem labore! Ipsa vitae
              optio dolores officiis ut cumque odio voluptatem earum!
              Consequuntur dolorum veritatis quaerat repudiandae! Lorem ipsum
              dolor sit amet consectetur adipisicing elit. Voluptatum rem vero
              officiis in autem labore! Ipsa vitae optio dolores officiis ut
              cumque odio voluptatem earum! Consequuntur dolorum veritatis
              quaerat repudiandae! optio dolores officiis ut cumque odio
              voluptatem earum! Consequuntur dolorum veritatis quaerat
              repudiandae! Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Voluptatum rem vero officiis in autem labore! Ipsa vitae
              optio dolores officiis ut cumque odio voluptatem earum!
              Consequuntur dolorum veritatis quaerat repudiandae!
            </p>
            <p className="text-center text-slate-400 font-Montserrat text-xs mb-10 tracking-widest">
              KEEP READING
            </p>

            <div className="flex justify-between items-center">
              <p className="text-center text-slate-400 font-Montserrat text-xs tracking-widest">
                MELLISA JOHNSON
              </p>
              <div className="flex justify-end items-center">
                <a
                  className="inline-block border rounded-full mr-2 text-gray-500 no-underline hover:text-gray-300 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 avatar"
                  data-tippy-content="@twitter_handle"
                  href="https://twitter.com/intent/tweet?url=#"
                >
                  <svg
                    className="fill-current h-4"
                    xmlns="https://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                  >
                    <path d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z"></path>
                  </svg>
                </a>
                <a
                  className="inline-block border rounded-full text-gray-500 no-underline hover:text-gray-300 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 avatar"
                  data-tippy-content="#facebook_id"
                  href="https://www.facebook.com/sharer/sharer.php?u=#"
                >
                  <svg
                    className="fill-current h-4"
                    xmlns="https://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                  >
                    <path d="M19 6h5V0h-5c-3.86 0-7 3.14-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z"></path>
                  </svg>
                </a>
              </div>
              <p className="text-center text-slate-400 font-Montserrat text-xs tracking-widest">
                August 31, 2018
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 p-3 md:p-5 flex flex-col items-center">
            <img
              src="https://source.unsplash.com/collection/325867/800x600"
              width={250}
              className="rounded-full h-64 mb-10"
              alt="bahamin"
            />
            <p className="text-center text-slate-800 font-semibold font-Montserrat text-xs mt-8 mb-4 tracking-widest">
              WHERE KEEP READING
            </p>
            <img
              src="https://backpacktraveler.qodeinteractive.com/wp-content/uploads/2018/08/footer-map-img.png"
              className="mb-10"
            />
            <p className="text-center text-slate-800 font-semibold font-Montserrat text-xs mt-8 mb-4 tracking-widest">
              INSTAGRAM
            </p>
            <div className="flex flex-wrap mb-10">
              <img
                src="https://source.unsplash.com/collection/325867/800x600"
                className="w-1/2 p-1"
              />
              <img
                src="https://source.unsplash.com/collection/764827/800x600"
                className="w-1/2 p-1"
              />
              <img
                src="https://source.unsplash.com/collection/494263/800x600"
                className="w-1/2 p-1"
              />
              <img
                src="https://source.unsplash.com/collection/225/800x600"
                className="w-1/2 p-1"
              />
            </div>
            <p className="text-center text-slate-800 font-semibold font-Montserrat text-xs mt-8 mb-6 tracking-widest">
              FOLLOW US
            </p>
            <SocialMedias />
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTopButton />
    </div>
  );
}
