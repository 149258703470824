import React from "react";
import ProjectBox from "./ProjectBox";

export default function Projects() {
  return (
    <div className=" container px-4 lg:px-10 py-5 bg-3">
      <div className="flex flex-wrap">
        <ProjectBox
          img="./img/t4.png"
          title="Online Marketplace"
          href="https://airbnb.bahamin.us"
        >
          <p>
            Completely similar to the Airbnb application, completely dynamic
            <br />
            with the capabilities of:
            <br />
            1. The possibility of user registration and login
            <br />
            2. Create post and promote and share it
            <br />
            3. See other people's posts and ads
            <br />
            4. Booking a house or a car or buying a ticket
            <br />
            5. Commenting and rating the posts
            <br />
            6. And...
            <br />
            By react js and without special backend, with Supabase, Fully
            responsive
            <br />
          </p>
        </ProjectBox>
        <ProjectBox
          img="./img/d5.png"
          title="Profesional Admin Dashboard"
          href="https://dashboard.bahamin.us"
        >
          <p>
            Fully responsive and with pages:
            <br />
            1. Analysis <br />
            2. Registration and login <br />
            3. Ecommerce <br />
            4. Content <br />
            5. Data list <br />
            6. Details <br />
            7. Tod-list <br />
            8. Survey <br />
            9. Chat
            <br />
          </p>
        </ProjectBox>
        <ProjectBox
          img="./img/course6.png"
          title="Online Courses"
          href="https://courssera.bahamin.us"
        >
          <p>
            Online store of educational courses, with the ability to register
            and access as a teacher or user, with a dedicated dashboard for both
            teachers and users, where teachers can share their courses with
            options such as course cost, course time, course photo. And ...
            Also, teachers can manage comments and messages in the admin
            dashboard and can upload articles
          </p>
        </ProjectBox>
        <ProjectBox
          img="./img/insta6.png"
          title="Business Strategy"
          href="https://instagram.bahamin.us"
        >
          <p>
            Instagram web application with features such as:
            <br />
            User registration and login
            <br />
            Having a personal profile page
            <br />
            Create and share posts
            <br />
            Following other users and being followed
            <br />
            Search between users
            <br />
            Change personal information
            <br />
            See new users and offer to follow
            <br />
            Like and comment on the post and ...
          </p>
        </ProjectBox>
      </div>
    </div>
  );
}
