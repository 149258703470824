import { useState } from "react";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";

export default function Subscribe() {
  const [input, setInput] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (input.length > 5 && input.length < 40) {
      Swal.fire({
        text: "Please wait for a moment",
        showDenyButton: false,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      try {
        emailjs
          .send(
            "service_05ga15n",
            "template_lc9vpfg",
            { email: input, message: "from blog subscribe" },
            "eW6UkCvaEXj9yrVmm"
          )
          .then((res) => {
            console.log(res);
            if (res.status === 200 && res.text === "OK") {
              Swal.fire({
                icon: "success",
                title: "Your message Send successfully",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "please try again",
              });
              window.location.reload();
            }
          });
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "please try again",
          text: error,
        });
        window.location.reload();
      }

      setInput("");
    } else {
      Swal.fire({
        icon: "error",
        title: "please check your information",
        text: "Your form is invalid",
      });
    }
  };
  return (
    <div className="container font-sans bg-green-100 rounded mt-8 p-4 md:p-24 text-center mb-6">
      <h2 className="font-bold break-normal text-2xl md:text-4xl">
        Subscribe to bahamin.us
      </h2>
      <h3 className=" break-normal font-normal text-gray-600 text-base md:text-xl">
        Get the latest posts delivered right to your inbox
      </h3>
      <div className="w-full text-center pt-4">
        <form onSubmit={handleSubmit}>
          <div className="max-w-xl mx-auto p-1 pr-0 flex flex-wrap items-center">
            <input
              type="email"
              placeholder="youremail@example.com"
              className="flex-1 appearance-none rounded shadow p-3 text-gray-600 mr-2 focus:outline-none"
              onChange={(e) => setInput(e.target.value)}
              value={input}
            />
            <button
              type="submit"
              className="flex-1 mt-4 md:mt-0 block md:inline-block appearance-none bg-green-500 text-white text-base font-semibold tracking-wider uppercase py-4 rounded shadow hover:bg-green-400"
            >
              Subscribe
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
