import Footer from "../../Components/Footer/Footer";
import Form from "../../Components/Form/Form";
import Header from "../../Components/Header/Header";
import SocialMedias from "../../Components/SocialMedias/SocialMedias";
import Questions from "../../Components/Questions/Questions";
import "./Contact.css";
import Background from "../../Components/Animations/Background";

export default function Contact() {
  return (
    <>
      <Background />
      <div className=" relative  overflow-x-hidden">
        <div className="contact-container">
          <Header />
          <div className="contact-div relative z-0 pt-32 -mt-24">
            <div className="container px-4 mx-auto">
              <h1 className="text-2xl lg:text-4xl  mb-5">Get an estimate</h1>
              <p className="lg:w-5/6 hover:scale-105 transition-all duration-500">
                Fill out this simple form. Our team will contact you promptly to
                discuss next steps.
              </p>
            </div>
          </div>
        </div>
        <Form />
        <div className="container px-4 mx-auto py-5">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 lg:w-1/3 flex mb-3">
              <i className="bx bx-envelope text-xl mr-3"></i>
              <div>
                <p className="color-gold1">Email:</p>
                <p>bahaminwp@gmail.com</p>
                <p>masoudbahamin@gmail.com</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 flex mb-3">
              <i className="bx bx-phone text-xl mr-3"></i>
              <div>
                <p className="color-gold1">Phone:</p>
                <p>+ 90 535484 3540</p>
                <p>+ 90 551964 3573</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 flex mb-3">
              <i className="bx bx-map-alt text-xl mr-3"></i>
              <div>
                <p className="color-gold1">Address:</p>
                <p>Ataturk Mah Karesi</p>
                <p>Balikesir Turkey</p>
              </div>
            </div>
          </div>
        </div>
        <Questions />
        <SocialMedias />
        <Footer />
      </div>
    </>
  );
}
