import React from 'react'
import ExhibitionBox from './ExhibitionBox'

export default function Exhibition() {
    return (
        <div className=' container py-5 bg-3'>
            <div className='row'>
                <ExhibitionBox img="./img/e1.jpg" title="Business Strategy" detail="Strategy develop customer in organisational knowledge and market perspectives that inform and provoke." />
                <ExhibitionBox img="./img/e1.jpg" title="Business Strategy" detail="Strategy develop customer in organisational knowledge and market perspectives that inform and provoke." />
                <ExhibitionBox img="./img/e1.jpg" title="Business Strategy" detail="Strategy develop customer in organisational knowledge and market perspectives that inform and provoke." />
                <ExhibitionBox img="./img/e1.jpg" title="Business Strategy" detail="Strategy develop customer in organisational knowledge and market perspectives that inform and provoke." />
                <ExhibitionBox img="./img/e1.jpg" title="Business Strategy" detail="Strategy develop customer in organisational knowledge and market perspectives that inform and provoke." />
                <ExhibitionBox img="./img/e1.jpg" title="Business Strategy" detail="Strategy develop customer in organisational knowledge and market perspectives that inform and provoke." />
            </div>
        </div>
    )
}
