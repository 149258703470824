import React from 'react'
import SkillItem from './SkillItem'

export default function Skills() {
  return (
    <div className=' container lg:px-10 py-8 bg-3'>
      <div className='flex flex-wrap'>
        <SkillItem title="HTML5" percent={90} />
        <SkillItem title="CSS3" percent={85} />
        <SkillItem title="JAVA SCRIPT" percent={75} />
        <SkillItem title="REACT" percent={75} />
        <SkillItem title="TYPESCRIPT" percent={70} />
        <SkillItem title="TAILWIND" percent={90} />
        <SkillItem title="BOOTSTRAP" percent={90} />
        <SkillItem title="FIREBASE" percent={40} />
      </div>
    </div>
  )
}
