import React, { useState } from "react";


export const themeContext = React.createContext(null)

export const ThemeContextProvider = ({ children }) => {

    const [theme, setTheme] = useState(localStorage.getItem("themeMode"))
    return (
        <themeContext.Provider value={{
            theme , setTheme
        }}>
            {children}
        </themeContext.Provider>
    )
}