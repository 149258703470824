import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ChatButton from "../Button/ChatButton";
import MenuModal from "../Modals/MenuModal";
import { themeContext } from "../../Contexts/ThemeContext";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const { theme, setTheme } = useContext(themeContext);

  const themeHandler = () => {
    setTheme(theme === "lightMode" ? "darkMode" : "lightMode");
  };

  useEffect(() => {
    localStorage.setItem("themeMode", theme);
    if (theme === "lightMode") {
      document.documentElement.style.setProperty("--bg-1", "#fff");
      document.documentElement.style.setProperty("--bg-2", "#fff");
      document.documentElement.style.setProperty("--bg-3", "#f8f9fa");
      document.documentElement.style.setProperty("--bg-4", "#f5f3f4");
      document.documentElement.style.setProperty("--bg-5", "#fff");
      document.documentElement.style.setProperty("--bg-6", "#fff");
      document.documentElement.style.setProperty("--bg-7", "#fff");
      document.documentElement.style.setProperty("--bg-8", "#fff");
      document.documentElement.style.setProperty("--bg-main", "#fff");
      document.documentElement.style.setProperty("--color-1", "#ffc576");
      document.documentElement.style.setProperty("--color-4", "#7A7A7A");
      document.documentElement.style.setProperty("--black", "#fff");
      document.documentElement.style.setProperty("--white", "#000");
      document.documentElement.style.setProperty("--primary-color", "#d7d7d7");
      document.documentElement.style.setProperty(
        "--separator-color",
        "#3a3a3a"
      );
      document.documentElement.style.setProperty("--gray", "#343a40");
      document.documentElement.style.setProperty("--gray-dark", "#6c757d");
      document.documentElement.style.setProperty("--secondary", "#343a40");
      document.documentElement.style.setProperty("--light", "#343a40");
      document.documentElement.style.setProperty("--light2", "#6c757d");
      document.documentElement.style.setProperty("--dark", "#f8f8f8");
    } else {
      document.documentElement.style.setProperty("--bg-1", "#2a2c47");
      document.documentElement.style.setProperty("--bg-2", "#1F2235");
      document.documentElement.style.setProperty("--bg-3", "#313552");
      document.documentElement.style.setProperty("--bg-4", "#2c304a");
      document.documentElement.style.setProperty("--bg-5", "#6e7c90");
      document.documentElement.style.setProperty(
        "--bg-6",
        "rgba(255,197,118,0.75)"
      );
      document.documentElement.style.setProperty("--bg-7", "#337ab7");
      document.documentElement.style.setProperty("--bg-8", "#000c35");
      document.documentElement.style.setProperty("--bg-main", "#1F2235");
      document.documentElement.style.setProperty("--color-1", "#ffc576");
      document.documentElement.style.setProperty("--color-4", "#7A7A7A");
      document.documentElement.style.setProperty("--black", "#000");
      document.documentElement.style.setProperty("--white", "#fff");
      document.documentElement.style.setProperty("--primary-color", "#3a3a3a");
      document.documentElement.style.setProperty(
        "--separator-color",
        "#d7d7d7"
      );
      document.documentElement.style.setProperty("--gray", "#6c757d");
      document.documentElement.style.setProperty("--gray-dark", "#343a40");
      document.documentElement.style.setProperty("--secondary", "#6c757d");
      document.documentElement.style.setProperty("--light", "#f8f9fa");
      document.documentElement.style.setProperty("--light2", "#f8f8f8");
      document.documentElement.style.setProperty("--dark", "#343a40");
    }
  }, [theme]);

  return (
    <div className=" sticky top-0 z-10">
      <header className="py-4 container px-4 mx-auto">
        <section className="flex">
          <div className="md:w-3/12  lg:w-5/12">
            <Link to="/">
              <img
                src={theme === "lightMode" ? "/img/lo1.png" : "/img/lo1.png"}
                className="w-24 md:w-36 lg:w-48"
                alt="bahamin.us"
              />
            </Link>
          </div>
          {/* desktop nav */}
          <div className="md:w-6/12 lg:w-4/12 md:flex md:items-center hidden">
            <ul className="flex gap-10 items-center">
              {theme === "lightMode" ? (
                <i
                  className="bx bx-moon text-md  cursor-pointer  hover-gold"
                  onClick={themeHandler}
                ></i>
              ) : (
                <i
                  className="bx bx-sun text-md  cursor-pointer  hover-gold"
                  onClick={themeHandler}
                ></i>
              )}
              <Link to="/">
                <li className=" cursor-pointer  hover-gold">HOME</li>
              </Link>
              <Link to="/price">
                <li className=" cursor-pointer  hover-gold">PRICE</li>
              </Link>
              <Link to="/blog">
                <li className=" cursor-pointer  hover-gold">BLOG</li>
              </Link>
              <Link to="/contact">
                <li className=" cursor-pointer  hover-gold">CONTACT</li>
              </Link>
            </ul>
          </div>
          <div className=" flex ml-auto justify-end md:w-2/12 lg:w-2/12">
            <div className="lg:block hidden">
              <ChatButton title="LET`S CHAT" to="/contact" />
            </div>
            {!showMenu ? (
              <i
                onClick={() => setShowMenu(true)}
                className="bx bx-menu  cursor-pointer  text-lg toggel-btn ms-auto"
              ></i>
            ) : (
              <i
                onClick={() => setShowMenu(false)}
                className="bx bx-x  cursor-pointer  text-lg toggel-btn ms-auto"
              ></i>
            )}
          </div>
        </section>
        {showMenu && (
          <MenuModal>
            <ChatButton title="LET`S CHAT" to="/contact" />
            {/* mobil nav */}
            <ul className="">
              {theme === "lightMode" ? (
                <p
                  className="cursor-pointer  hover-gold mt-4"
                  onClick={themeHandler}
                >
                  <i className="bx bx-moon text-md"></i> Dark Mood
                </p>
              ) : (
                <p
                  className="cursor-pointer  hover-gold mt-4"
                  onClick={themeHandler}
                >
                  <i className="bx bx-sun text-md"></i> Light Mood
                </p>
              )}
              <Link to="/">
                <li className=" cursor-pointer  hover-gold py-3 border-b">
                  HOME
                </li>
              </Link>
              <Link to="/price">
                <li className=" cursor-pointer  hover-gold py-3 border-b">
                  PRICE
                </li>
              </Link>

              <Link to="/blog">
                <li className=" cursor-pointer hover-gold py-3 border-b">
                  BLOG
                </li>
              </Link>
              <Link to="/contact">
                <li className=" cursor-pointer  hover-gold py-3 border-b">
                  CONTACT
                </li>
              </Link>
            </ul>
          </MenuModal>
        )}
      </header>
    </div>
  );
}
