import React from 'react'

export default function Slid({ detail, name, title }) {
  return (
    <div className='p-3'>
      <div className='d-flex mb-4'>
        <div >
          <h3>{name}</h3>
          <p className='fs-12 color-gold1 fw-500'>{title}</p>
        </div>
        <img src='./img/i1.png' className='wd-50p rounded-50 ms-auto' />
      </div>
      <p className='fs-12 fw-500'>
        {detail}
      </p>
    </div>
  )
}
