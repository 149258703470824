import React from 'react'
import AwardBox from './AwardBox'

export default function Awards() {
    return (
        <div className=' container py-5 bg-3'>
            <div className='row'>
                <AwardBox img="./img/a1.png" title="Business Strategy" detail="Strategy develop customer in organisational knowledge and market perspectives that inform and provoke." />
                <AwardBox img="./img/a1.png" title="Business Strategy" detail="Strategy develop customer in organisational knowledge and market perspectives that inform and provoke." />
                <AwardBox img="./img/a1.png" title="Business Strategy" detail="Strategy develop customer in organisational knowledge and market perspectives that inform and provoke." />
                <AwardBox img="./img/a1.png" title="Business Strategy" detail="Strategy develop customer in organisational knowledge and market perspectives that inform and provoke." />
            </div>
        </div>
    )
}
