import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./Slider.css";
import { Pagination, Navigation } from "swiper";
import "swiper/css/navigation";
import { articles } from "../Data/Data";
import { Link } from "react-router-dom";

export default function SliderBlog1() {
  return (
    <>
      <div className="slider-blog1">
        <Swiper
          slidesPerView={4}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {articles.map((article) => (
            <SwiperSlide key={article.id}>
              <Link to={`/post/${article.title}`}>
                <img className="w-64 h-64" src={article.src} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
