import React, { useEffect, useState } from 'react'

export default function ScrollTopButton() {

    const [show, setShow] = useState(false)

    const scrollFun = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        const showBtn = () => {
            if (window.pageYOffset > 300) {
                setShow(true)
            } else {
                setShow(false)
            }
        }

        window.addEventListener("scroll", showBtn)
        return () => {
            window.removeEventListener("scroll", showBtn)
        }
    }, [])
    return (<>
        {show ? (
            <button
                onClick={scrollFun}
                className="fixed right-5 bottom-5 w-12 h-12 hidden md:flex justify-center items-center border rounded-full" type="button" title="Scroll to top">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{fill: "rgba(0, 0, 0, 1)"}}><path d="m6.293 11.293 1.414 1.414L12 8.414l4.293 4.293 1.414-1.414L12 5.586z"></path><path d="m6.293 16.293 1.414 1.414L12 13.414l4.293 4.293 1.414-1.414L12 10.586z"></path></svg>
            </button>) : (<></>)}
    </>

    )
}
