import { useState } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import { Link } from 'react-router-dom'

export default function Questions() {

    const [tab, setTab] = useState("1")

    return (
        <>
            <SectionHeader title="FAQ Section" subTitle=" helps set expectations" />
            <div className='container mx-auto'>
                <p className='my-5 px-4'>These questions cover some of the common inquiries potential clients may have about website design and development services.</p>
                <div className='flex flex-wrap md:flex-nowrap gap-1 md:gap-20  bg-3 rounded-xl p-8 lg:p-12 h-full'>
                    <div className='w-full md:w-2/4 h-full md:h-fit mb-5 md:mb-0'>
                        <p className={`${tab === "1" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-t  border-indigo-100 border-solid`}
                            onClick={() => setTab("1")}
                        >How much does website design and development cost?</p>
                        <p className={`${tab === "2" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                            onClick={() => setTab("2")}
                        >What is the typical timeframe for designing and developing a website?</p>
                        <p className={`${tab === "3" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                            onClick={() => setTab("3")}
                        >Do you offer custom website design or use templates?</p>
                        <p className={`${tab === "4" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                            onClick={() => setTab("4")}
                        >Can you provide examples of websites you have designed?</p>
                        <p className={`${tab === "5" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                            onClick={() => setTab("5")}
                        >What platforms or programming languages do you specialize in?</p>
                        <p className={`${tab === "6" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                            onClick={() => setTab("6")}
                        >Can you assist with website maintenance and updates?</p>
                        <p className={`${tab === "7" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                            onClick={() => setTab("7")}
                        >Do you offer e-commerce website development services?</p>
                        <p className={`${tab === "8" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                            onClick={() => setTab("8")}
                        >Are SEO (Search Engine Optimization) services included in website design and development?</p>
                        <p className={`${tab === "9" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                            onClick={() => setTab("9")}
                        >Can you help with website hosting and domain registration?</p>
                        <p className={`${tab === "10" ? " scale-110" : "text-slate-400"} transition duration-500 cursor-pointer border-b py-3 mb-0 border-indigo-100 border-solid`}
                            onClick={() => setTab("10")}
                        >What type of support do you provide after the website is launched?</p>

                    </div>
                    <div className='w-full md:w-2/4 relative h-full'>
                        <div className={`transition-all duration-700`}>
                            <p className='text-xl lg:text-2xl mb-5'>Answer : </p>
                            {tab === "1" ? (
                                <p className='lg:w-5/6 hover:scale-105 transition-all duration-500'>
                                    The cost of website design and development can vary significantly based on factors such as the complexity of the website, the functionality required,
                                    the level of customization, the industry, the expertise of the developer/designer, and the region/country where the work is being done.
                                    <br />
                                    On average, approximate costs are listed on the <Link to="/price">PRICE</Link> page
                                    <br />
                                    It is important to note that these are just rough estimates, and the actual cost can be lower or higher based on the specific requirements and unique needs of each project.
                                </p>
                            ) : tab === "2" ? (
                                <p className='lg:w-5/6 hover:scale-105 transition-all duration-500'>
                                    The timeframe for designing and developing a website can also vary based on several factors, such as the complexity of the website, the size of the project, the availability of resources, and the responsiveness of the client in providing feedback and content.
                                    <br />
                                    For a basic informational website with a few pages, the timeframe can range from a few weeks to a couple of months. This includes the time required for initial planning, design and development, content creation, and testing.
                                    <br />
                                    More complex websites with additional features and functionality typically take longer to design and develop. This can range from a few months to several months or even a year, depending on the scope of the project.
                                    <br />
                                    It is important to note that these are general estimates, and the actual timeframe can vary.
                                </p>
                            ) : tab === "3" ? (
                                <p className='lg:w-5/6 hover:scale-105 transition-all duration-500'>
                                    When it comes to website design, there are generally two approaches: custom design and template-based design.
                                    <br />
                                    Custom website design involves creating a unique design from scratch, tailored to the specific needs and requirements of the client. This approach offers more flexibility and customization options but may require more time and resources.
                                    <br />
                                    Template-based design involves using pre-designed templates or themes that can be customized to fit the client's branding and content. Templates can significantly reduce the design and development time and may be a more cost-effective option. However, customization options may be limited compared to custom design.
                                    <br />
                                    The choice between custom design and template-based design depends on factors such as budget, timeline, desired level of uniqueness, and specific project requirements. We do both of them
                                </p>
                            ) : tab === "4" ? (
                                <p className='lg:w-5/6 hover:scale-105 transition-all duration-500'>
                                    I recommend that you go to the portfolio section to see examples of web design and see different styles and approaches.
                                </p>
                            ) : tab === "5" ? (
                                <p className='lg:w-5/6 hover:scale-105 transition-all duration-500'>
                                    I specialize in programming languages html, css, javascript and technologies Tailwind, Bootstrap, Sass and React, and I also specialize in WordPress platform.
                                </p>
                            ) : tab === "6" ? (
                                <p className='lg:w-5/6 hover:scale-105 transition-all duration-500'>
                                    Yes, I can provide information and guidance regarding website maintenance and updates. Website maintenance involves regularly monitoring, managing, and updating a website to ensure its optimal functionality, security, and performance.
                                    <br />
                                    Here are some key aspects of website maintenance and updates:
                                    <br />
                                    1. Content Updates: Regularly updating and refreshing website content, such as text, images, and videos, to keep the information current and relevant.
                                    <br />
                                    2. Security: Implementing security measures, such as installing updates and patches, monitoring for vulnerabilities, and implementing backup systems, to protect the website from potential threats.
                                    <br />
                                    3. Performance Optimization: Conducting routine performance checks, optimizing website speed, and addressing any performance issues or errors.
                                    <br />
                                    4. Bug Fixes: Identifying and resolving any technical issues, bugs, or errors that may arise on the website.
                                </p>
                            ) : tab === "7" ? (
                                <p className='lg:w-5/6 hover:scale-105 transition-all duration-500'>
                                    E-commerce website development involves creating an online platform that enables businesses to sell products or services online. It typically includes features such as product listings, shopping carts, payment gateways, secure transactions, and order management systems.
                                    <br />
                                    we develop e-commerce website
                                    <br />
                                    It's important to note that e-commerce website development can be complex. we can assist with the technical aspects and provide guidance throughout the development process.
                                </p>
                            ) : tab === "8" ? (
                                <p className='hover:scale-105 transition-all duration-500'>
                                    SEO (Search Engine Optimization) services are not typically included as a standard part of website design and development. However, we  offer SEO services as an additional service or as part of a larger package.
                                    <br />
                                    Website design and development primarily focus on creating a well-designed and functional website that meets the client's requirements. This includes aspects such as visual design, user experience, responsiveness, and functionality.
                                    <br />
                                    On the other hand, SEO is a separate process that involves optimizing a website to improve its visibility in search engine results and drive organic traffic. SEO typically includes keyword research, on-page optimization, technical optimization, content creation, link building, and other strategies to enhance a website's search engine ranking.
                                    <br />
                                    While website design and development can lay the foundation for effective SEO, the actual SEO work, such as keyword optimization, content creation, and off-page optimization, is usually performed after the website is live.
                                </p>
                            ) : tab === "9" ? (
                                <p className='lg:w-5/6 hover:scale-105 transition-all duration-500'>
                                    Yes, I can help with website hosting and domain registration.
                                </p>
                            ) : (
                                <p className='lg:w-5/6 hover:scale-105 transition-all duration-500'>
                                    After the website is launched, I can provide ongoing technical support, maintenance, and troubleshooting assistance. This includes addressing any issues or bugs that may arise, ensuring the website is up and running smoothly, and making any necessary updates or changes.
                                    <br />
                                    I can also help with website backups and security measures to protect your website from any potential threats. Additionally, I can assist with implementing new features or functionalities, optimizing the website's performance, and providing guidance on website analytics and optimization.
                                    <br />
                                    Overall, my goal is to ensure that your website continues to function properly, remains secure, and meets your evolving needs even after the launch.
                                </p>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
