import React from 'react'

export default function AwardBox({title , detail , img}) {
  return (
    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 my-3 pe-3'>
            <div className='row align-items-center'>
                <div className='col-lg-4 col-md-8 col-sm-12'>
                    <img src={img} className="img-100p rounded-15p"/>
                </div>
                <div className='col-lg-8 col-md-12 col-sm-12'>
                    <h3 className='h4 my-3'>{title}</h3>
                    <p className='pe-3'>{detail}</p>
                </div>
            </div>
        </div>
  )
}
