import { useParams } from "react-router-dom";
import { articles } from "../../Components/BlogComponents/Data/Data";
import Author from "../../Components/BlogComponents/Author/Author";
import NormalCard from "../../Components/BlogComponents/Cards/NormalCard";
import Subscribe from "../../Components/BlogComponents/Subscribe/Subscribe";
import Header from "./../../Components/BlogComponents/Header/Header";
import { useEffect, useState } from "react";
import ScrollTopButton from "../../Components/BlogComponents/ScrollTopButton/ScrollTopButton";
import Spiner from "../../Components/BlogComponents/Spiner/Spiner";

export default function Post() {
  const [article, setArticle] = useState(null);

  const { postId } = useParams();

  useEffect(() => {
    let article1 = articles.filter((item) => item.title === postId);
    setArticle(article1[0]);
  }, [postId]);

  if (!article)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spiner />
      </div>
    );

  return (
    <div className="bg-white">
      <Header />

      <div className="text-center pt-16 md:pt-32">
        <p className="text-sm md:text-base text-green-500 font-bold">
          {article.date || "02 JULY 2023"}{" "}
          <span className="text-gray-900">/</span>
          18 Min
        </p>
        <h1 className="font-bold break-normal text-3xl md:text-5xl">
          {" "}
          {article?.title}
        </h1>
      </div>

      <div
        className="container w-full max-w-6xl mx-auto bg-white bg-cover mt-8 rounded"
        style={{ backgroundImage: `url(${article?.src})`, height: "75vh" }}
      ></div>

      <div className="container max-w-5xl mx-auto -mt-32">
        <div className="mx-0 sm:mx-6">
          <div
            className="bg-white w-full p-8 md:p-24 text-xl md:text-2xl text-gray-800 leading-normal"
            style={{ fontFamily: "Georgia,serif" }}
          >
            <p className="text-2xl md:text-3xl mb-5 text-slate-700">
              {article?.title}
            </p>

            {article?.desc?.split("--").map((p, index) => (
              <p
                key={index}
                className="my-6 text-slate-500 font-Montserrat text-sm"
              >
                {p}
              </p>
            ))}

            <img src={article?.img || article?.src} width={600} />
          </div>

          <Subscribe />

          <Author author={article.author} />
        </div>
      </div>

      <div className="bg-gray-200">
        <div className="container w-full max-w-6xl mx-auto px-2 py-8">
          <div className="flex flex-wrap -mx-2">
            {[...articles]
              .sort(() => 0.5 - Math.random())
              .map((article, index) => {
                if (index <= 2) {
                  return (
                    <NormalCard
                      key={article.id}
                      widthclassName={"w-1/3"}
                      heightclassName="h-64"
                      {...article}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
      </div>
      <ScrollTopButton />
    </div>
  );
}
