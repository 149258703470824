import React from 'react'

export default function SkillItem({ title, percent }) {
  return (
    <div className='w-full md:w-1/2 p-6'>
      <div className='flex mb-3'>
        <p className='fs-14'>{title}</p>
        <p className='ml-auto color-gold1 fs-14'>{percent}%</p>
      </div>
      <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
        <div className="progress-bar" style={{ width: `${percent}%` }}></div>
      </div>
    </div>
  )
}
