import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import "./Sliders.css";

// import required modules
import { Pagination } from "swiper";

export default function Slider1() {
  return (
    <div className="slider1-container">
      <Swiper
        direction={"vertical"}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="flex justify-around hover-display border rounded-xl overflow-hidden">
            <a href="https://store.bahamin.us" target="blunk">
              <div className="flex mobil-screen-slid8">
                {/* <div className="">
                                    <img src="/img/store11.jpg" className="w-full bg-yellow-300" />
                                </div> */}
                <div
                  className="hidden mt-auto hoverd-display-block text-start p-4 w-full rounded-b-xl"
                  style={{ backgroundColor: "rgb(0,0,0,.7)" }}
                >
                  <h3 className="text-lg">Online store</h3>
                  <p className="color-gold1">
                    Here are some key features and highlights of our website:
                    <br />
                    Modern and Responsive Design
                    <br />
                    Smooth User Experience
                    <br />
                    Robust Product Catalog
                    <br />
                    Secure Shopping Cart and Checkout
                    <br />
                    Integration with Third-Party Services
                    <br />
                    Efficient Inventory Management
                    <br />
                    SEO-Optimized
                  </p>
                </div>
              </div>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-around hover-display border rounded-xl overflow-hidden">
            <a href="https://airbnb.bahamin.us" target="blunk">
              <div className="flex mobil-screen-slid1">
                <div
                  className="hidden mt-auto hoverd-display-block text-start p-4 w-full rounded-b-xl"
                  style={{ backgroundColor: "rgb(0,0,0,.7)" }}
                >
                  <h3 className="text-lg">Online Marketplace</h3>
                  <p className="color-gold1">
                    this is a online marketplace . Here are some notable
                    features of an this website:
                    <br />
                    Accommodation Listings
                    <br />
                    Search Functionality
                    <br />
                    User Profiles
                    <br />
                    Booking and Reservation System
                    <br />
                    Messaging System
                    <br />
                    Reviews and Ratings
                    <br />
                    Payment Processing
                  </p>
                </div>
              </div>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-around hover-display border rounded-xl overflow-hidden bg-yellow-200">
            <a href="https://store.bahamin.us" target="blunk">
              <div className="w-[340px] sm:w-[400px] md:w-[550px] lg:w-[700px] xl:w-[850px] relative md:flex">
                <div className="flex md:gap-4">
                  <div className="flex bg-slate-200">
                    <img src="/img/152.png" className="w-1/2 bg-yellow-300" />
                    <img src="/img/153.png" className="w-1/2 bg-yellow-300" />
                  </div>
                  <div className="hidden sm:flex bg-slate-300">
                    <img src="/img/151.png" className="w-1/2 bg-yellow-600" />
                    <img src="/img/154.png" className="w-1/2 bg-yellow-600" />
                  </div>
                </div>
                <div
                  className="hidden mt-auto absolute bottom-0 hoverd-display-block text-start p-4 w-full rounded-b-xl"
                  style={{ backgroundColor: "rgb(0,0,0,.7)" }}
                >
                  <h3 className="text-lg">Online store</h3>
                  <p className="color-gold1">
                    Internet taxi website as a platform to connect passengers to
                    taxi drivers through the Internet
                    <br />
                    Here are some key features and highlights of our website:
                    <br />
                    Online reservation system
                    <br />
                    Show live map
                    <br />
                    Calculate the cost of travel
                    <br />
                    User account management
                    <br />
                    Display your location and destination location
                    <br />
                    Modern and Responsive Design
                    <br />
                    Smooth User Experience
                  </p>
                </div>
              </div>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-around hover-display border rounded-xl overflow-hidden">
            <a href="https://instagram.bahamin.us" target="blunk">
              <div className="flex mobil-screen-slid2">
                <div
                  className="hidden mt-auto hoverd-display-block text-start p-4 w-full rounded-b-xl"
                  style={{ backgroundColor: "rgb(0,0,0,.7)" }}
                >
                  <h3 className="text-lg">Instagram Web Application</h3>
                  <p className="color-gold1">
                    A video and photo sharing social network, just like
                    Instagram
                    <br />
                    Photo and Video Sharing
                    <br />
                    Feed
                    <br />
                    Explore
                    <br />
                    Follow and Like posts
                  </p>
                </div>
              </div>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-around hover-display border rounded-xl overflow-hidden">
            <a href="https://dashboard.bahamin.us" target="blunk">
              <div className="flex mobil-screen-slid3">
                <div
                  className="hidden mt-auto hoverd-display-block text-start p-4 w-full rounded-b-xl"
                  style={{ backgroundColor: "rgb(0,0,0,.7)" }}
                >
                  <h3 className="text-lg">Profesional Admin Dashboard</h3>
                  <p className="color-gold1">
                    A professional and widely used admin dashboard with 3
                    applications
                    <br />
                    Here are some common features found in an admin dashboard
                    website:
                    <br />
                    Data Visualization
                    <br />
                    Content Management
                    <br />
                    Analytics and Reporting
                    <br />
                    Data Management
                  </p>
                </div>
              </div>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-around hover-display border rounded-xl overflow-hidden">
            <a href="https://courssera.bahamin.us" target="blunk">
              <div className="flex mobil-screen-slid4">
                <div
                  className="hidden mt-auto hoverd-display-block text-start p-4 w-full rounded-b-xl"
                  style={{ backgroundColor: "rgb(0,0,0,.7)" }}
                >
                  <h3 className="text-lg">Online Course Marketplace</h3>
                  <p className="color-gold1">
                    A store of various training courses with this features:
                    <br />
                    User Registration and Login
                    <br />
                    Course Catalog
                    <br />
                    Course Pages
                    <br />
                    Payment Processing
                    <br />
                    Shopping Cart
                    <br />
                    Reviews and Ratings
                    <br />
                    Mobile Responsiveness
                  </p>
                </div>
              </div>
            </a>
          </div>
        </SwiperSlide>

        {/* <SwiperSlide>
                    <div className='flex justify-around hover-display border rounded-xl overflow-hidden'>
                        <a href="https://courssera.bahamin.us" target="blunk">
                            <div className='flex mobil-screen-slid6'>
                                <div className='hidden mt-auto hoverd-display-block text-start p-4 w-full rounded-b-xl' style={{ backgroundColor: "rgb(0,0,0,.7)" }}>
                                    <h3 className='text-lg'>Online Course Marketplace</h3>
                                    <p className="color-gold1">A store of various training courses with many features</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </SwiperSlide> */}
        <SwiperSlide>
          <div className="flex justify-around hover-display border rounded-xl overflow-hidden">
            <a href="https://store.bahamin.us" target="blunk">
              <div className="mobil-screen-slid5 relative flex">
                <div className="md:flex items-center md:gap-4 hidden">
                  <div className=" bg-slate-200">
                    <img
                      src="/img/mobil-store.png"
                      className="w-full bg-yellow-300"
                    />
                  </div>
                  <div className=" bg-slate-300">
                    <img
                      src="/img/Laptop-store.png"
                      className="w-full bg-yellow-600"
                    />
                  </div>
                </div>
                <div
                  className="hidden mt-auto md:absolute bottom-0 hoverd-display-block text-start p-4 w-full rounded-b-xl"
                  style={{ backgroundColor: "rgb(0,0,0,.7)" }}
                >
                  <h3 className="text-lg">Online store</h3>
                  <p className="color-gold1">
                    Here are some key features and highlights of our website:
                    <br />
                    Modern and Responsive Design
                    <br />
                    Smooth User Experience
                    <br />
                    Robust Product Catalog
                    <br />
                    Secure Shopping Cart and Checkout
                    <br />
                    Integration with Third-Party Services
                    <br />
                    Efficient Inventory Management
                    <br />
                    SEO-Optimized
                  </p>
                </div>
              </div>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-around hover-display border rounded-xl overflow-hidden">
            <a href="https://wallet.bahamin.us" target="blunk">
              <div className="mobil-screen-slid7 relative flex">
                <div className="md:flex items-center md:gap-4 hidden">
                  <div className=" bg-slate-200">
                    <img src="/img/mobil-wallet.png" className="w-full " />
                  </div>
                  <div className=" bg-slate-300">
                    <img src="/img/Laptop-wallet.png" className="w-full " />
                  </div>
                </div>
                <div
                  className="hidden mt-auto md:absolute bottom-0 hoverd-display-block text-start p-4 w-full rounded-b-xl"
                  style={{ backgroundColor: "rgb(0,0,0,.7)" }}
                >
                  <h3 className="text-lg">Blockchain App & Crypto Wallet</h3>
                  <p className="color-gold1">
                    Introducing Blockchain App & Crypto Wallet Website:
                    <br />
                    User-Friendly Interface
                    <br />
                    Multi-Currency Support
                    <br />
                    Real-Time Market Data
                  </p>
                </div>
              </div>
            </a>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
