import React from 'react'
import { Link } from 'react-router-dom'

export default function NormalCard({widthclassName , heightclassName , title , desc,src,id}) {

    return (
        <div className={`w-full md:${widthclassName} p-6 flex flex-col flex-grow flex-shrink`}>
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
                <Link to={`/post/${title}`} className="flex flex-wrap no-underline hover:no-underline">
                    <img src={src}
                        className={`${heightclassName} w-full rounded-t pb-6`} />
                    <p className="w-full text-gray-600 text-xs md:text-sm px-6">{Math.floor(Math.random() * 60) } Min ago</p>
                    <div className="w-full  font-bold text-xl text-gray-900 px-6 mb-4">{title}</div>
                    <p className="text-gray-800 font-serif text-base px-6 mb-5 line-clamp-2">
                       {desc}
                    </p>
                </Link>
            </div>
            <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
                <div className="flex items-center justify-between">
                    <img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name"
                        src="https://i.pravatar.cc/300" alt="Avatar of Author" />
                    <p className="text-gray-600 text-xs md:text-sm">1 MIN READ</p>
                </div>
            </div>
        </div>
    )
}
