import React from 'react'

export default function ExhibitionBox({ detail, title, img }) {
    return (
        <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 my-3'>
            <div className='d-flex justify-content-around hover-display'>
                <div className='p-3 d-flex rounded-15p' style={{ backgroundImage: `url(${img})`, width: 380, height: 300 }}>
                    <div className='d-none mt-auto hoverd-display-block'>
                        <h3 className='h4'>{title}</h3>
                        <p>{detail}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
