import React from 'react'

export default function ProjectBox({ title, children, img ,href }) {
    return (
        <div className='w-full md:w-1/2 my-3 pe-3'>
            <div className='flex flex-wrap gap-8'>
                <div className='md:w-1/3 sm:w-1/2 w-full'>
                    <a href={href} target="blunk" className='w-full'>
                        <img src={img} className="img-100 rounded-xl mx-auto" />
                    </a>
                </div>
                <div className='w-full lg:w-2/3 col-lg-8'>
                    <h3 className='text-lg my-3'>{title}</h3>
                    <p className=' line-clamp-4'>{children}</p>
                    <a className='fs-12 color-gold1 cursor-pointer'>SEE MORE</a>
                </div>
            </div>
        </div>
    )
}
