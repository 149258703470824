import React, { useReducer } from 'react'

const reducer = (state, action) => {
    switch (action.type) {
        case "INPUT": {
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            }
        }
        default: {
            return state
        }
    }
}

const validator = (value, arrey) => {
    const falseArrey = []
    arrey.forEach(element => {
        if (element.name === "req") {
            value.length < 1 && falseArrey.push(false)
        }
        else if (element.name === "min") {
            value.length < element.min && falseArrey.push(false)
        }
        else if (element.name === "max") {
            value.length > element.max && falseArrey.push(false)
        }
        else if (element.name === "email") {
            !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) && falseArrey.push(false)
        }
    });

    if (falseArrey.length > 0) {
        return false
    }

    return true
}



export default function Input({ id, inputHandler, validations, type, className, placeholder, elem }) {

    const [input, dispatch] = useReducer(reducer, { value: "", isValid: false })

    const changeInput = event => {
        dispatch({
            type: "INPUT",
            value: event.target.value,
            isValid: validator(event.target.value, validations)
        })
        inputHandler(id, event.target.value, validator(event.target.value, validations))
    }



    return (
        <>
            {elem !== "textarea" ? (
                <div className='relative'>
                    <input
                        type={type}
                        className={className}
                        placeholder={placeholder}
                        value={input.value}
                        onChange={changeInput}
                    />
                    {input.isValid && <i className='bx bx-check-shield text-green-500 absolute right-10' style={{ right: "8%", top: "35%" }}></i>}
                </div>
            ) : (
                <div className='relative'>
                    <textarea
                        type={type}
                        className={className}
                        placeholder={placeholder}
                        value={input.value}
                        onChange={changeInput}
                        style={{ width: "95%", height: 140 }}
                    />
                    {input.isValid && <i className='bx bx-check-shield text-green-500 absolute right-10' style={{ right: "8%", top: "35%" }}></i>}
                </div>
            )}
        </>
    )
}
