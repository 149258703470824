import { Link } from 'react-router-dom'

export default function MainCard({ widthclassName, heightclassName, title, desc, src, id }) {
    return (
        <div className="flex h-full bg-white rounded overflow-hidden shadow-lg mb-8">
            <Link to={`/post/${title}`} className="flex flex-wrap no-underline hover:no-underline">
                <div className="w-full md:w-2/3 rounded-t">
                    <img src={src}
                        className="h-full w-full shadow" />
                </div>

                <div className="w-full md:w-1/3 flex flex-col flex-grow flex-shrink">
                    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
                        <p className="w-full text-gray-600 text-xs md:text-md pt-6 px-6 font-Montserrat">18 Min ago</p>
                        <div className="w-full font-semibold text-xl text-gray-900 px-6 font-Montserrat">{title}</div>
                        <p className="text-gray-800 font-serif text-base px-6 mb-5 line-clamp-4">{desc}</p>
                    </div>

                    <div
                        className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
                        <div className="flex items-center justify-between">
                            <img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name"
                                src="https://i.pravatar.cc/300" alt="Avatar of Author" />
                            <p className="text-gray-600 text-xs md:text-sm">1 MIN READ</p>
                        </div>
                    </div>
                </div>

            </Link>
        </div>
    )
}
