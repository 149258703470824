import React, { useReducer, useState } from 'react'
import ChatButton from '../Button/ChatButton'
import SectionHeader from '../SectionHeader/SectionHeader'
import "./Form.css"
import Input from './Input'
import { emailValid, maxValid, minValid, reqValid } from './rouls'
import emailjs from '@emailjs/browser';
import Swal from "sweetalert2"
import AnimationDiv from '../Animations/AnimationDiv'

const reducer = (state, action) => {
    switch (action.type) {
        case "FORM": {
            let formValid = true
            for (const key in state.inputs) {
                if (key === action.id) { // key=name  action.id=email
                    formValid = formValid && action.isValid
                } else {
                    formValid = formValid && state.inputs[key].isValid
                }
            }
            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    [action.id]: {
                        value: action.value,
                        isValid: action.isValid
                    }
                },
                formValid: formValid
            }
        }
        default: return state
    }
}

export default function Form() {

    const [buget, setBuget] = useState("")
    const [phone, setphone] = useState("")

    const [formState, dispatch] = useReducer(reducer, {
        inputs: {
            name: {
                value: "",
                isValid: false
            },
            email: {
                value: "",
                isValid: false
            },
            text: {
                value: "",
                isValid: false
            },
        },
        formValid: false
    })

    const inputHandler = (id, value, isValid) => {
        dispatch({
            type: "FORM",
            id, value, isValid
        })
    }


    const form = {
        from_name: formState.inputs.name.value,
        to_name: formState.inputs.email.value,
        message: formState.inputs.text.value,
        phone: phone || "empty",
        buget: buget || "empty"
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formState.formValid && (buget.length < 15) && (phone.length < 18)) {
            Swal.fire({
                text: "Please wait for a moment",
                showDenyButton: false,
                allowOutsideClick: false,
                showConfirmButton: false
            })
            try {
                emailjs.send('service_05ga15n', "template_lc9vpfg", form, 'eW6UkCvaEXj9yrVmm')
                    .then(res => {
                        console.log(res);
                        if (res.status === 200 && res.text === "OK") {
                            Swal.fire({
                                icon: "success",
                                title: "Your message Send successfully"
                            })
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "please try again"
                            })
                            window.location.reload()
                        }
                    });
            } catch (error) {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "please try again",
                    text: error
                })
                window.location.reload()
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "please check your information",
                text: "Your form is invalid"
            })
        }


    }


    return (
        <div className='container relative mx-auto bg-3 p-5 -mt-32 z-[5] shadow-md rounded-md'>
            <div className=' pt-6 pb-4'>
                <p className='color-gold1'>CONTACT ME</p>
                <h2 className='text-lg lg:text-4xl my-4'>Let’s Chat</h2>
            </div>
            <div className='' >
                <p className='mb-4'>Fill this out so we can learn more about you and your needs.</p>
                <form onSubmit={handleSubmit}>
                    <div className='flex flex-wrap'>
                        <div className='w-full md:w-1/2 mb-4' style={{ padding: 0 }}>
                            <AnimationDiv position={"right"}><Input
                                validations={[reqValid(), minValid(3), maxValid(16)]}
                                id="name"
                                inputHandler={inputHandler}
                                type="text" placeholder='Full name' className='form-input' />
</AnimationDiv>
                        </div>
                        <div className='w-full md:w-1/2 mb-4' style={{ padding: 0 }}>
                            <AnimationDiv position={"left"}><Input
                                validations={[reqValid(), minValid(6), maxValid(32), emailValid()]}
                                id="email"
                                inputHandler={inputHandler}
                                type="text" placeholder='Email' className='form-input' />
</AnimationDiv>
                        </div>
                    </div>

                    <div className='flex flex-wrap'>
                        <div className='w-full md:w-1/2 mb-4' style={{ padding: 0 }}>
                            <AnimationDiv position={"right"}><input type="text" placeholder='Budget' name='budget' className='form-input mb-4'
                                value={buget} onChange={e => setBuget(e.target.value)}
                            /></AnimationDiv>
                            <AnimationDiv position={"left"}><input type="text" placeholder='Phone number' name='phone' className='form-input'
                                value={phone} onChange={e => setphone(e.target.value)}
                            /></AnimationDiv>
                        </div>
                        <div className='w-full md:w-1/2 mb-4' style={{ padding: 0 }}>
                            <AnimationDiv position={"right"}><Input
                                elem="textarea"
                                validations={[reqValid(), minValid(4), maxValid(202)]}
                                id="text"
                                inputHandler={inputHandler}
                                type="text" placeholder='Message' className='form-input'
                                style={{ width: "95%", height: 140 }}
                            /></AnimationDiv>
                            {/* <textarea type="text" placeholder='Message' className='form-input'  /> */}
                        </div>
                    </div>
                    <div >
                        <button type='submit' className='border-gold  bg-transparent color-gold1 py-2 px-10 rounded-sm hover-bg-gold'>Send</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
