import Index from "./Pages";
import Contact from "./Pages/Contact/Contact";
import Price from "./Pages/Price/Price";
import Blog from "./Pages/Blog/Index";
import Post from "./Pages/Blog/Post";

const routs = [
    {path:"/" , element:<Index />},
    {path:"/contact" , element:<Contact />},
    {path:"/price" , element:<Price />},
    {path:"/blog" , element:<Blog />},
    {path:"/post/:postId" , element:<Post />},
]

export default routs