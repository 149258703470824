import React from 'react'

export default function PriceBox({title , desc1 , desc2 , desc3 , price ,hour ,price2 , icon}) {
    return (
        <div className='w-[300px] sm:w-full p-4 py-8 z-10 mx-auto'>
            <div className='bg-4 rounded-lg p-8 shadow-sm'>
            <i className={`${icon} text-4xl mb-6 rounded-full p-3 border-amber-600 border-solid border-2`} ></i>
                <h2 className='h2 mb-3'>{title}</h2>
                <p className='fs-14 mb-3'>{desc1}</p>
                <p className='fs-14 mb-3'>{desc2}</p>
                <p className='fs-14 mb-3'>{desc3}</p>
                <del className='fs-14 mb-3'>${price} - ${price2}</del>
                <p className='fs-14 mb-3'>${price/2} - ${price2/2}</p>
                <p className='fs-14 mb-5'>
                    <span className='fs-2 color-gold1'>${hour}</span> /Hour
                </p>
                <button className='btn border-gold rounded color-gold1 p-3 hover-bg-gold'>GET STARTED</button>
            </div>
        </div>
    )
}
