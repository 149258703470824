import React from 'react'

export default function NormalCard({ title, img, desc ,children}) {
    return (
        <div className='w-full text-center p-4'>
            {children}
            {/* <img src={`/img/${img}`} className='w-full mb-6'  style={{  filter: "opacity(30%)"}}/> */}
            <h3 className='text-lg mt-0 my-2 lg:my-8'>{title}</h3>
            <p>{desc}</p>
        </div>
    )
}
