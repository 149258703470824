import SocialBox from "./SocialBox";
import SectionHeader from "../SectionHeader/SectionHeader";
import AnimationDiv from "../Animations/AnimationDiv";

export default function SocialMedias() {
  return (
    <div>
      <SectionHeader
        title="Contact Us In Social Media"
        subTitle="SOCIAL MEDIA"
      />
      <div className="container mx-auto py-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <AnimationDiv position={"right"}>
            <SocialBox
              href="https://www.linkedin.com/in/masoud-bahamin"
              title="Linkeding"
              subTitle="Professional social profile"
            >
              <i className="bx bxl-linkedin"></i>
            </SocialBox>
          </AnimationDiv>
          <AnimationDiv position={"right"}>
            <SocialBox
              href="https://www.instagram.com/mr______masoud/"
              title="Instagram"
              subTitle="mr______masoud"
            >
              <i className="bx bxl-instagram"></i>
            </SocialBox>
          </AnimationDiv>
          <AnimationDiv position={"left"}>
            <SocialBox
              href="mailto: bahaminwp@gmail.com"
              title="Email"
              subTitle="Bahaminwp@gmail.com"
            >
              <i className="bx bx-envelope"></i>
            </SocialBox>
          </AnimationDiv>
          <AnimationDiv position={"left"}>
            <SocialBox
              href="https://github.com/masoud-bahamin"
              title="GitHub"
              subTitle="Masoud-bahamin"
            >
              <i className="bx bxl-github"></i>
            </SocialBox>
          </AnimationDiv>
          <AnimationDiv position={"left"}>
            <SocialBox
              href="https://www.t.me/masoudbhmn"
              title="Telegram"
              subTitle="@masoudbhmn"
            >
              <i className="bx bxl-telegram"></i>
            </SocialBox>
          </AnimationDiv>
          <AnimationDiv position={"right"}>
            <SocialBox
              href="https://web.whatsapp.com/send?phone=905354843540"
              title="Whats Up"
              subTitle="00905354843540"
            >
              <i className="bx bxl-whatsapp"></i>
            </SocialBox>
          </AnimationDiv>
        </div>
      </div>
    </div>
  );
}
