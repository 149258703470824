import React from 'react'
import "./Modals.css"

export default function MenuModal({ children }) {
    return (
        <div className='menu-modal z-50'>
            {children}
        </div>
    )
}
