import React from 'react'

export default function ServiceBox({title , detail , children}) {
  return (
    <div className='w-full md:w-1/2 lg:w-1/3 col-lg-4 my-5 pr-5'>
        <>{children}</>
        <h3 className='text-lg my-5'>{title}</h3>
        <p>{detail}</p>
    </div>
  )
}
