import React from "react";
import { Link } from "react-router-dom";
import ChatButton from "../Button/ChatButton";

export default function Footer() {
  return (
    <footer className=" py-6 bg-4">
      <section className="container px-4 mx-auto">
        <div className="flex text-center">
          <div className="lg:w-1/3 sm:w-1/2 w-full mb-4">
            <img src="./img/lo1.png" className=" wd-150p" />
          </div>

          <div className="hidden md:block lg:w-1/3 sm:w-1/2 w-full mb-4">
            <div>
              <h2 className="text-2xl mb-5">BAHAMIN</h2>
              <ul className="flex justify-center gap-8 fs-14">
                <Link to="/">
                  <li className=" cursor-pointer hover-gold">HOME</li>
                </Link>
                <Link to="/price">
                  <li className=" cursor-pointer hover-gold">PRICE</li>
                </Link>
                {/* <Link to="/"><li className=' cursor-pointer hover-gold'>SHOP</li></Link> */}
                {/* <Link to="/"><li className=' cursor-pointer hover-gold'>BLOG</li></Link> */}
                <Link to="/contact">
                  <li className=" cursor-pointer hover-gold">CONTACT</li>
                </Link>
              </ul>
            </div>
          </div>

          <div className="lg:w-1/3 sm:w-1/2 w-full mb-4">
            <ChatButton title="LET`S CHAT" />
          </div>
        </div>
        <p className="fs-12 mt-5 text-center">Copyright bahamin.us - 2022</p>
      </section>
    </footer>
  );
}
