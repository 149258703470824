import React from 'react'
import PriceBox from './PriceBox'
import AnimationDiv from '../Animations/AnimationDiv'

export default function Prices() {
  return (
    <div className='container mx-auto mb-5' style={{ marginTop: "-150px" }}>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-4'>
        <AnimationDiv position={"right"}>
          <PriceBox icon="bx bx-leaf" title="Basic" desc1="Custom design," desc2="up to 5 pages," desc3="responsive" price={500} price2={1000} hour={10} />
        </AnimationDiv>
        <AnimationDiv position={"left"}>
          <PriceBox icon="bx bx-spa" title="Standard" desc1="Custom design," desc2="up to 10 pages," desc3="responsive" price={1000} price2={2000} hour={15} />
        </AnimationDiv>
        <AnimationDiv position={"left"}>
          <PriceBox icon="bx bxs-tree-alt" title="Premium" desc1="Custom design," desc2="unlimited pages," desc3="responsive" price={2000} price2={3000} hour={20} />
        </AnimationDiv>
        <AnimationDiv position={"right"}>
          <PriceBox icon="bx bxs-plane-alt" title="E-commerce" desc1="Custom design," desc2="online store integration," desc3="responsive" price={3000} price2={5000} hour={25} />
        </AnimationDiv>
        <AnimationDiv position={"left"}>
          <PriceBox icon="bx bxs-clinic" title="Maintenance" desc1="Monthly updates," desc2=" security checks," desc3="backups" price={100} price2={300} hour={15} />
        </AnimationDiv>
      </div>

    </div>
  )
}
