import "./Spiner.css"

export default function Spiner() {
  return (
    <div>
        <div className='rounded-full border-4 border-t-slate-300 border-dashed border-slate-600 w-16 h-16 flex justify-center items-center crcle'>
            <div className='text-6xl text-slate-300 dot'>..</div>
        </div>
    </div>
  )
}
