import React from 'react'

export default function SocialBox({ title, subTitle, children , href }) {
    return (
        <div className='p-4' >
            <div className='border-gold p-4 rounded flex hover-bg-gold'>
                <a href={href}  target="_blank">
                    <div>
                        <h3 className='text-lg'>{title}</h3>
                        <p className='color-gold1 hover-black'>{subTitle}</p>
                    </div>
                </a>
                <div className='ml-auto text-4xl p-color5'>
                    {children}
                </div>
            </div>
        </div>
    )
}
